import { Link } from "gatsby"
import { gsap } from "gsap/all"
import SplitText from "gsap/SplitText"
import * as React from "react"
import { COMPONENT_DELAY_MS } from "../../helpers/constants"
import ArrowRightBlack from '../../images/arrow-right-black.svg'

const ArrowButton = ({
  children,
  back,
  to,
  onClick,
}) => {

  const elRef = React.useRef()
  const firstRef = React.useRef()
  const secondRef = React.useRef()
  const firstArrowRef = React.useRef()
  const secondArrowRef = React.useRef()

  let firstLetters = null
  let secondLetters = null

  const onMouseEnter = () => {
    gsap.set(secondLetters.chars, {
      opacity: 0
    })
    gsap.to(firstLetters.chars, {
      y: '-100%',
      ease: "power2.inOut",
      stagger: 0.01,
      duration: 0.3,
      opacity: 0
    })
    gsap.to(secondLetters.chars, {
      y: '-100%',
      ease: "power2.inOut",
      stagger: 0.01,
      duration: 0.3,
      opacity: 1
    })
    // Arrow
    gsap.to(firstArrowRef.current, {
      x: '100%',
      ease: "power4.inOut",
      duration: 0.3
    })
    gsap.to(secondArrowRef.current, {
      x: '0%',
      ease: "power4.inOut",
      duration: 0.3,
      delay: 0.2
    })
  }

  const onMouseLeave = () => {
    gsap.to(secondLetters.chars, {
      y: '0%',
      ease: "power2.inOut",
      stagger: 0.01,
      duration: 0.3,
      opacity: 1
    })
    gsap.to(firstLetters.chars, {
      y: '0%',
      ease: "power2.inOut",
      stagger: 0.01,
      duration: 0.3,
      opacity: 1
    })
    // Arrow
    gsap.to(firstArrowRef.current, {
      x: '0',
      ease: "power4.inOut",
      duration: 0.3,
      delay: 0.2
    })
    gsap.to(secondArrowRef.current, {
      x: '-100%',
      ease: "power4.inOut",
      duration: 0.3,
    })
  }

  React.useEffect(() => {
    firstLetters = new SplitText(firstRef.current, {
      type: "chars",
      charsClass: "letter"
    });
    secondLetters = new SplitText(secondRef.current, {
      type: "chars",
      charsClass: "letter"
    });

    gsap.set(secondArrowRef.current, {
      top: 0,
      left: 0,
      x: '-100%'
    })
  }, [])

  return (
    <a href={to} onClick={(e) => {
      if (onClick) {
        onClick(e)
      }
    }} onMouseEnter={() => {
      onMouseEnter()
    }} onMouseLeave={() => {
      onMouseLeave()
    }} ref={elRef} className="arrow-button flex items-center">
      <div className={`${back ? 'order-2' : 'order-1'} overflow-hidden relative`}>
        <div ref={firstRef}>
          {children}
        </div>
        <div ref={secondRef} className="absolute">
          {children}
        </div>
      </div>
      <div className={`${back ? 'order-1 transform rotate-180 mr-2' : 'order-2 ml-2'} relative overflow-hidden`}>
        <img ref={firstArrowRef} src={ArrowRightBlack} alt="Arrow" />
        <img ref={secondArrowRef} className="absolute" src={ArrowRightBlack} alt="Arrow" />
      </div>
    </a>
  )
}

export default ArrowButton