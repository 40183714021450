import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OGImage from "../images/og.png"
import { gsap } from "gsap/all"
import Breadcrumb from "../components/ui/breadcrumb"
import Share from "../components/ui/share"
import '../styles/disaster.css'
import utils from "../helpers/utils"
import ArrowButton from "../components/ui/arrow-button"
import {
  SplitText
} from 'gsap/SplitText';
import { useMediaQuery } from "react-responsive"
import responsive from "../helpers/responsive"
import { SplitText as ST } from 'gsap/SplitText'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from "../components/nav"
import { Power2 } from "gsap/gsap-core"
import transitions from "../helpers/transitions"
import { Expo } from "gsap/gsap-core"

gsap.registerPlugin(SplitText);

export default function Disaster({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { nodes } = allMarkdownRemark

  const elRef = React.useRef()
  const titleRef = React.useRef()
  const mobileTitleRef = React.useRef()
  const contentRef = React.useRef()
  const figureDisasterMainRef = React.useRef()
  const articleRef = React.useRef()
  const mobileTopDetailsRef = React.useRef()

  const mainImage = getImage(data.markdownRemark.frontmatter.mainImage)

  let titleWords = null

  let mobileTitleWords = null

  const setupAnimation = () => {
    if (!responsive.isDesktop()) {
      return
    }
    titleWords = new ST(titleRef.current, { type: 'words', wordsClass: 'word' })

    titleWords.words.forEach((word) => {
      const div = document.createElement('div')
      div.classList.add('word-container')
      utils.wrap(word, div)
    })

    gsap.set(titleWords.words, {
      yPercent: 100
    })

    gsap.set(contentRef.current.querySelectorAll('p'), {
      opacity: 0
    })

    gsap.set('.disaster-sub-images .figure', {
      opacity: 0
    })

    gsap.set('.share-title', {
      opacity: 0
    })

    gsap.set('.share-title', {
      opacity: 0
    })

    gsap.set('.social-icons button, .social-icons .button', {
      opacity: 0,
      scale: 0
    })

    gsap.set('.arrow-button', {
      y: 20,
      opacity: 0
    })

    gsap.set('.disaster-count div', {
      yPercent: 100
    })

    gsap.set('.disaster-breadcrumb .breadcrumb-inner', {
      yPercent: 200
    })

    // On finish
    gsap.set('body', {
      opacity: 1
    })
  }

  const animateIn = () => {
    if (!responsive.isDesktop()) {
      return
    }
    setupAnimation()

    const delay0 = 0.2
    const delay1 = 1.5
    const delay2 = 2

    const durationShort = 1.2
    const durationLong = 2

    const ease = "power4.inOut"

    gsap.to(titleWords.words, {
      yPercent: 0,
      ease: ease,
      stagger: 0.1,
      duration: durationLong,
      delay: delay0
    })

    gsap.to(contentRef.current.querySelectorAll('p'), {
      opacity: 1,
      ease: ease,
      stagger: 0.2,
      duration: durationShort,
      delay: delay1
    })

    gsap.to('.disaster-sub-images .figure', {
      opacity: 1,
      ease: ease,
      stagger: 0.4,
      duration: durationShort,
      delay: delay2
    })

    gsap.to('.share-title', {
      opacity: 1,
      ease: ease,
      duration: durationLong,
      delay: delay2
    })

    gsap.to('.social-icons button, .social-icons .button', {
      opacity: 1,
      scale: 1,
      stagger: 0.1,
      ease: ease,
      duration: durationLong,
      delay: delay2
    })

    gsap.to('.arrow-button', {
      y: 20,
      opacity: 1,
      stagger: 0.1,
      ease: ease,
      duration: durationLong,
      delay: delay2
    })

    gsap.to('.disaster-count div', {
      yPercent: 0,
      ease: ease,
      duration: durationLong,
      delay: delay2
    })

    gsap.to('.disaster-breadcrumb .breadcrumb-inner', {
      yPercent: 0,
      ease: ease,
      duration: durationLong,
      stagger: 0.2,
      delay: delay2
    })

    gsap.to(figureDisasterMainRef.current, {
      opacity: 1,
      duration: durationShort,
      ease: ease,
      delay: delay0
    })

    // gsap.to(figureDisasterMainRef.current.querySelector('img'), {
    //   objectPosition: "center",
    //   scale: 1,
    //   duration: durationLong,
    //   ease: ease,
    //   delay: delay0
    // })

    gsap.to('.nav', {
      duration: durationShort,
      y: 0,
      delay: delay1,
      ease: Power2.easeInOut
    })
  }

  const setupMobileAnimation = () => {
    mobileTitleWords = new ST(mobileTitleRef.current, { type: 'words', wordsClass: 'word' })

    mobileTitleWords.words.forEach((word) => {
      const div = document.createElement('div')
      div.classList.add('word-container')
      utils.wrap(word, div)
    })

    gsap.set(mobileTitleWords.words, {
      yPercent: 100
    })

    gsap.set(contentRef.current.querySelectorAll('p'), {
      opacity: 0
    })

    gsap.set(mobileTopDetailsRef.current, {
      opacity: 0
    })

    utils.showBody()
  }

  const animateInMobile = () => {
    if (responsive.isDesktop()) {
      return
    }

    const delay0 = 0.2
    const delay1 = 1.5
    const delay2 = 2

    const durationShort = 1.2
    const durationLong = 2

    const ease = "power4.inOut"

    setupMobileAnimation()
    transitions.animateInNavAndFooter()
    gsap.to(mobileTitleWords.words, {
      yPercent: 0,
      ease: ease,
      stagger: 0.1,
      duration: durationLong,
      delay: delay0
    })

    gsap.to(contentRef.current.querySelectorAll('p'), {
      opacity: 1,
      ease: ease,
      stagger: 0.2,
      duration: durationShort,
      delay: delay1
    })
    gsap.to(figureDisasterMainRef.current, {
      opacity: 1,
      duration: durationShort,
      ease: ease,
      delay: delay0
    })
    gsap.to(mobileTopDetailsRef.current, {
      opacity: 1,
      delay: delay0,
      ease: ease,
      duration: durationShort
    })
  }


  React.useEffect(() => {
    document.body.classList.add('has-entered')
    // TODO: Remove
    utils.setTheme('light')
    animateIn()
    animateInMobile()
  }, [])



  const getNextSlug = () => {
    let nextIndex = 0
    // console.log(frontmatter.order)
    if (frontmatter.order == nodes.length) {
      nextIndex = 0
    } else {
      nextIndex = frontmatter.order
    }
    // console.log(nextIndex)
    // console.log(nodes[nextIndex].frontmatter.slug)
    return nodes[nextIndex].frontmatter.slug
  }
  const navigateNextArticle = e => {
    e.preventDefault()
    gsap.to('.nav', {
      duration: 0.4,
      opacity: 0,
      ease: "power4.inOut"
    })
    gsap.to(elRef.current, {
      y: '-70',
      opacity: 0,
      ease: "power4.inOut",
      duration: 0.6,
      onComplete: () => {
        navigate(getNextSlug())
      }
    })
  }

  return (
    <Layout inverse={true}>
      <SEO
        title={`${frontmatter.title} ${frontmatter.title2} | Restore Hope Appeal`}

        image={OGImage}
      />
      <Nav inverse={true} />
      <div ref={elRef} className="disaster-container">
        {
          useMediaQuery(responsive.isMobileQuery) &&
          <div ref={mobileTopDetailsRef} className="flex items-center justify-between py-4 border-black border-b mb-4">
            <ArrowButton back={true} to="/" onClick={transitions.goHome}>
              Home
            </ArrowButton>
            <div className="flex items-center subtext">
              <div className="pr-8">{frontmatter.category}</div>
              <div>{frontmatter.order}/{nodes.length}</div>
            </div>
          </div>
        }
        {
          useMediaQuery(responsive.isDesktopQuery) && <header className="disaster-header flex justify-between pb-12">
            <ArrowButton back={true} to="/" onClick={transitions.goHome}>
              Home
            </ArrowButton>
            <h1 className="relative" ref={titleRef}>{frontmatter.title}<div className="text-indent">{frontmatter.title2}</div></h1>
            <ArrowButton to={getNextSlug()}
              onClick={navigateNextArticle}>
              Next Article
            </ArrowButton>
          </header>
        }

        <article ref={articleRef} className="flex-1 flex flex-col lg:flex-row">
          <div className="disaster-image-container">
            <div className="disaster-count">
              <div>{frontmatter.order}/{nodes.length}</div>
            </div>
            <div className="figure-disaster-main" ref={figureDisasterMainRef}>
              <GatsbyImage className="figure-disaster-main-img" loading="eager" image={mainImage} alt={frontmatter.title} />
            </div>
          </div>

          <div className="disaster-middle-content">
            <Breadcrumb hideBorder={true} className="disaster-breadcrumb | hidden lg:block transform rotate-90 translate-y-full origin-left" leftText="Latest News" rightText="26 Mar 2021" />

            <div>
              <div className="disaster-sub-images">
                <figure className="figure figure--tiny">
                  <img className="figure-image" src={frontmatter.secondImage.publicURL} alt="Typhoon Goni" />
                </figure>
                <figure className="figure figure--tiny">
                  <img className="figure-image" src={frontmatter.thirdImage.publicURL} alt="Typhoon Goni" />
                </figure>
              </div>

              <div className="flex justify-between items-center">
                <Share title={`${frontmatter.title} ${frontmatter.title2} | Restore Hope Appeal`} url={`https://compassionappeal.znstudio.com.au${frontmatter.slug}`} isDark={true} />
                <div className="block lg:hidden">
                  <ArrowButton to={getNextSlug()}
                    onClick={navigateNextArticle}>
                    Next Article
                  </ArrowButton>
                </div>
              </div>
            </div>
          </div>

          <div className="disaster-article-text">
            {
              useMediaQuery(responsive.isMobileQuery) &&
              <h1 className="relative" ref={mobileTitleRef}>{frontmatter.title} {frontmatter.title2}</h1>
            }
            <div
              ref={contentRef}
              className="double-column double-column--animate"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>

        </article>
      </div>

    </Layout >

  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        order
        category
        slug
        title
        title2
        mainImage {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 25, placeholder: NONE)
          }
        }
        secondImage {
          publicURL
        }
        thirdImage {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {slug: {ne: null}}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        frontmatter {
          slug
          order
        }
      }
    }
  }
`